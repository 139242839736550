import { ReactEventHandler, useEffect, useRef } from 'react';
import Link from 'next/link';
import { noop } from 'lodash-es';

import { Box, chakra, Flex, Heading, Text, useToken } from '@chakra-ui/react';

import { ButtonLink } from '~/components/button-link';
import { ContentContainer } from '~/components/container';
import { Glyph } from '~/components/glyph';
import { spaceHeight } from '~/components/space';
import { trackEvent } from '~/features/analytics/track-event';
import { TemplateButton as TemplateButtonType } from '~/features/page-template/models/template-button';

export type HeroSectionProps = {
  title: string;
  subtitle?: string;
  primaryCta?: TemplateButtonType;
  secondaryCta?: TemplateButtonType;
  onSchoolCardClick?: (schoolTitle: string) => void;
  isFreeTrialOn?: boolean;
};

export function HeroSection({
  title,
  subtitle,
  primaryCta,
  secondaryCta,
  onSchoolCardClick,
  isFreeTrialOn = false,
}: HeroSectionProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    // Note: this effect is an optimization to improve Lighthouse scores.
    // We don't really need the video to load until after the page has loaded since we have a poster to render in place of the video.
    const video = videoRef.current;
    if (video) {
      for (let source in video.children) {
        const videoSource = video.children[source] as HTMLSourceElement;
        if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
          videoSource.src = videoSource.dataset.src as string;
        }
      }
      video.load();
    }
  }, []);

  const playVideo: ReactEventHandler<HTMLVideoElement> = (e) => {
    if (e.currentTarget.readyState >= 0) {
      e.currentTarget.play().catch(noop);
    }
  };

  return (
    <Box
      as='section'
      bgGradient='linear(to-b, blue.800, blue.800)'
      overflow='hidden'
      paddingY={spaceHeight}
      position='relative'
    >
      <Box bottom='0px' height='100%' left='50%' position='absolute' transform='translateX(-50%)' width='100%'>
        <chakra.video
          ref={videoRef}
          bottom='0px'
          height='100%'
          left='0px'
          objectFit='cover'
          pointerEvents='none'
          position='absolute'
          poster='/videos/waves-poster.webp'
          right='0px'
          top='0px'
          width='100%'
          sx={{
            '&::-webkit-media-controls-start-playback-button': {
              display: 'none !important',
            },
          }}
          loop
          muted
          playsInline
          onLoadedMetadata={playVideo}
          onSuspend={playVideo}
        >
          <source data-src='/videos/waves.webm' type='video/webm' />
          <source data-src='/videos/waves-h265.mp4' type='video/mp4' />
          <source data-src='/videos/waves-h264.mp4' type='video/mp4' />
        </chakra.video>
      </Box>
      <Flex flexDir='column' position='relative'>
        <ContentContainer color='white' textAlign='center'>
          <Heading
            as='h1'
            fontWeight='400'
            marginBottom={isFreeTrialOn ? 'unset' : '16px'}
            size='xl'
            whiteSpace='pre-wrap'
          >
            {title}
          </Heading>
        </ContentContainer>
        {isFreeTrialOn && (
          <>
            <Box h='40px' />
            <ContentContainer color='white' textAlign='center'>
              <ButtonLink
                buttonProps={{
                  colorScheme: 'sea-foam',
                  mb: 4,
                  onClick: () =>
                    trackEvent({
                      name: 'Free Trial CTA Clicked',
                      type: 'click',
                      label: '7 day free trial',
                      value: 'CTA 1',
                    }),
                }}
                linkProps={{
                  href: '/checkout/individual/monthly/credit-card',
                }}
              >
                Start a Free 7-Day Trial
              </ButtonLink>
              <Text fontWeight='500'>Try risk-free. Cancel anytime.</Text>
            </ContentContainer>
          </>
        )}
        <Box h='40px' />
        <SchoolCards onCardClick={onSchoolCardClick} />
      </Flex>
    </Box>
  );
}

export const schoolCards = [
  {
    label: 'Data Science',
    glyph: 'school-data-sci-one',
    path: '/school/data-science',
  },
  {
    label: 'Programming & Development',
    glyph: 'school-programming-one',
    path: '/school/programming',
  },
  {
    label: 'Artificial Intelligence',
    glyph: 'school-ai-one',
    path: '/school/artificial-intelligence',
  },
  {
    label: 'Business',
    glyph: 'school-business-one',
    path: '/school/business',
  },
  {
    label: 'Autonomous Systems',
    glyph: 'school-autonomous-sys-one',
    path: '/school/autonomous-systems',
  },
  {
    label: 'Product Management',
    glyph: 'school-product-mgt-one',
    path: '/school/product-management',
  },
  {
    label: 'Cloud Computing',
    glyph: 'school-cloud-computing-one',
    path: '/school/cloud-computing',
  },
];

export function SchoolCards({ onCardClick }: { onCardClick?: (schoolTitle: string) => void }) {
  const [seaFoam] = useToken('colors', ['accent.sea-foam.500']);

  return (
    <Box aria-label='schools navigation' as='nav' margin='auto' maxW={{ base: '100%', xl: '70rem' }}>
      <Flex as='ul' gap='16px' listStyleType='none' overflow='auto' paddingX={{ base: '16px', xl: 0 }}>
        {schoolCards.map((schoolCard) => {
          return (
            <chakra.li key={schoolCard.label} display='flex' flex='1'>
              <Flex
                alignItems='center'
                as={Link}
                bg='white'
                borderRadius='base'
                flex={1}
                flexDirection='column'
                href={schoolCard.path}
                justifyContent='center'
                minWidth='144px'
                overflow='auto'
                p='16px'
                width={0}
                _hover={{
                  boxShadow: `inset 0 0 0 3px ${seaFoam}`,
                }}
                onClick={() => onCardClick?.(schoolCard.label)}
              >
                <Glyph name={schoolCard.glyph} />
                <Text color='black' textAlign='center'>
                  {schoolCard.label}
                </Text>
              </Flex>
            </chakra.li>
          );
        })}
      </Flex>
    </Box>
  );
}
